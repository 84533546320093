import React from "react";

import Image from "@components/atoms/Image";

import * as Styled from "./styles";
import { ImageBoxProps } from "./types";

const ImageBox: React.FC<ImageBoxProps> = ({ image }) =>
  image ? (
    <Styled.Figure>
      <Image {...image} />
    </Styled.Figure>
  ) : null;

export default ImageBox;

import React from "react";
import { storyblokEditable } from "@storyblok/react";

import { getImage } from "@utils/getImage";
import ImageBox from "@components/atoms/ImageBox";

import { ImageStoryblokProps } from "./types";

const ImageStoryblok: React.FC<ImageStoryblokProps> = ({ blok }) => {
  const { image: img } = blok;

  const image = getImage(img);

  return <ImageBox {...{ image, ...storyblokEditable(blok) }} />;
};

export default ImageStoryblok;
